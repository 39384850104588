import { graphql, useStaticQuery } from 'gatsby'
import formatNodes from '../utils/formatNodes'

const useHomePage = () => {
  const nodes = useStaticQuery(
    graphql`
      fragment FluidImage on File {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }

      query {
        heroImage: file(relativePath: { eq: "homepage_hero.png" }) {
          ...FluidImage
        }
        dotsBGImage: file(relativePath: { eq: "homepage_dots_bg.png" }) {
          ...FluidImage
        }
        piccollageImage: file(relativePath: { eq: "homepage_piccollage.png" }) {
          ...FluidImage
        }
        value1Image: file(relativePath: { eq: "homepage_value_01.jpg" }) {
          ...FluidImage
        }
        value2Image: file(relativePath: { eq: "homepage_value_02.jpg" }) {
          ...FluidImage
        }
        productBGImageMobile: file(
          relativePath: { eq: "homepage_products_m.png" }
        ) {
          ...FluidImage
        }
        productBGImage: file(relativePath: { eq: "homepage_products.png" }) {
          ...FluidImage
        }
      }
    `
  )
  return formatNodes(nodes)
}

export default useHomePage
