import { graphql, useStaticQuery } from 'gatsby'
import formatNodes from '../utils/formatNodes'

const useProductsPage = () => {
  const nodes = useStaticQuery(
    graphql`
      fragment FluidImage on File {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }

      query {
        appStore: file(relativePath: { eq: "products_appstore.png" }) {
          childImageSharp {
            fixed(width: 120, height: 41) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        playStore: file(relativePath: { eq: "products_google-play.png" }) {
          childImageSharp {
            fixed(width: 132, height: 39) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        slack: file(relativePath: { eq: "products_slack.png" }) {
          childImageSharp {
            fixed(width: 122, height: 48) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        piccollageIcon: file(
          relativePath: { eq: "products_piccollage_icon.png" }
        ) {
          ...FluidImage
        }
        piccollageBanner: file(
          relativePath: { eq: "products_piccollage.jpg" }
        ) {
          ...FluidImage
        }
        onBeatIcon: file(relativePath: { eq: "products_onbeat_icon.jpg" }) {
          ...FluidImage
        }
        onBeatBanner: file(relativePath: { eq: "products_onbeat.jpg" }) {
          ...FluidImage
        }
        beamBanner: file(relativePath: { eq: "products_beam.png" }) {
          ...FluidImage
        }
        beamIcon: file(relativePath: { eq: "products_beam_icon.png" }) {
          ...FluidImage
        }
        noodleBanner: file(relativePath: { eq: "products_noodle.png" }) {
          ...FluidImage
        }
        noodleIcon: file(relativePath: { eq: "products_noodle_icon.png" }) {
          ...FluidImage
        }
        becomeBanner: file(relativePath: { eq: "products_become.png" }) {
          ...FluidImage
        }
        becomeIcon: file(relativePath: { eq: "products_become_icon.png" }) {
          ...FluidImage
        }
        snapJoyIcon: file(relativePath: { eq: "products_snap_joy_icon.png" }) {
          ...FluidImage
        }
        snapJoyBanner: file(relativePath: { eq: "products_snap_joy.jpg" }) {
          ...FluidImage
        }
        visageIcon: file(relativePath: { eq: "products_visage_icon.jpg" }) {
          ...FluidImage
        }
        visageBanner: file(relativePath: { eq: "products_visage.jpg" }) {
          ...FluidImage
        }
        cardeeIcon: file(relativePath: { eq: "products_cardee_icon.png" }) {
          ...FluidImage
        }
        cardeeBanner: file(relativePath: { eq: "products_cardee.jpg" }) {
          ...FluidImage
        }
        enchantIcon: file(relativePath: { eq: "products_enchant_icon.jpg" }) {
          ...FluidImage
        }
        enchantBanner: file(relativePath: { eq: "products_enchant.jpg" }) {
          ...FluidImage
        }
        stickerizeIcon: file(
          relativePath: { eq: "products_stickerize_icon.jpg" }
        ) {
          ...FluidImage
        }
        stickerizeBanner: file(
          relativePath: { eq: "products_stickerize.jpg" }
        ) {
          ...FluidImage
        }
        chatlyIcon: file(relativePath: { eq: "products_chatly_icon.jpg" }) {
          ...FluidImage
        }
        chatlyBanner: file(relativePath: { eq: "products_chatly.jpg" }) {
          ...FluidImage
        }
        memeMeIcon: file(relativePath: { eq: "products_meme_me_icon.jpg" }) {
          ...FluidImage
        }
        memeMeBanner: file(relativePath: { eq: "products_meme_me.jpg" }) {
          ...FluidImage
        }
        fastCutAiIcon: file(
          relativePath: { eq: "products_fast_cut_ai_icon.jpg" }
        ) {
          ...FluidImage
        }
        fastCutAiBanner: file(
          relativePath: { eq: "products_fast_cut_ai.jpg" }
        ) {
          ...FluidImage
        }
        hiLiteIcon: file(relativePath: { eq: "products_hilite_icon.jpg" }) {
          ...FluidImage
        }
        hiLiteBanner: file(relativePath: { eq: "products_hilite.jpg" }) {
          ...FluidImage
        }
        sidebarIcon: file(relativePath: { eq: "products_sidebar_icon.jpg" }) {
          ...FluidImage
        }
        sidebarBanner: file(relativePath: { eq: "products_sidebar.jpg" }) {
          ...FluidImage
        }
        sparkoIcon: file(relativePath: { eq: "products_sparko_icon.jpg" }) {
          ...FluidImage
        }
        sparkoBanner: file(relativePath: { eq: "products_sparko.jpg" }) {
          ...FluidImage
        }
        productsBannerMobile: file(relativePath: { eq: "products_top_m.jpg" }) {
          ...FluidImage
        }
        productsBanner: file(relativePath: { eq: "products_top.jpg" }) {
          ...FluidImage
        }
        vibesIcon: file(relativePath: { eq: "products_vibes_icon.png" }) {
          ...FluidImage
        }
        vibesBanner: file(relativePath: { eq: "products_vibes.jpg" }) {
          ...FluidImage
        }
        widgetgoIcon: file(relativePath: { eq: "products_widgetgo_icon.png" }) {
          ...FluidImage
        }
        widgetgoBanner: file(relativePath: { eq: "products_widgetgo.jpg" }) {
          ...FluidImage
        }
        deckTheCardIcon: file(
          relativePath: { eq: "products_deckthecard_icon.png" }
        ) {
          ...FluidImage
        }
        deckTheCardBanner: file(
          relativePath: { eq: "products_deckthecard.jpg" }
        ) {
          ...FluidImage
        }
        washYourPawsIcon: file(
          relativePath: { eq: "products_washyourpaws_icon.jpg" }
        ) {
          ...FluidImage
        }
        washYourPawsBanner: file(
          relativePath: { eq: "products_washyourpaws.jpg" }
        ) {
          ...FluidImage
        }
        convertIcon: file(relativePath: { eq: "products_convert_icon.png" }) {
          ...FluidImage
        }
        convertBanner: file(relativePath: { eq: "products_convert.jpg" }) {
          ...FluidImage
        }
        piccovideoIcon: file(
          relativePath: { eq: "products_piccovideo_icon.png" }
        ) {
          ...FluidImage
        }
        piccovideoBanner: file(
          relativePath: { eq: "products_piccovideo.jpg" }
        ) {
          ...FluidImage
        }
        picCutIcon: file(relativePath: { eq: "products_piccut_icon.png" }) {
          ...FluidImage
        }
        picCutBanner: file(relativePath: { eq: "products_piccut.png" }) {
          ...FluidImage
        }

        quickmoIcon: file(relativePath: { eq: "products_quickmo_icon.png" }) {
          ...FluidImage
        }
        quickmoBanner: file(relativePath: { eq: "products_quickmo.jpg" }) {
          ...FluidImage
        }
        salutIcon: file(relativePath: { eq: "products_salut_icon.png" }) {
          ...FluidImage
        }
        salutBanner: file(relativePath: { eq: "products_salut.jpg" }) {
          ...FluidImage
        }
        quickmoIcon: file(relativePath: { eq: "products_quickmo_icon.png" }) {
          ...FluidImage
        }
        quickmoBanner: file(relativePath: { eq: "products_quickmo.jpg" }) {
          ...FluidImage
        }
        cattitudeIcon: file(
          relativePath: { eq: "products_cattitude_icon.png" }
        ) {
          ...FluidImage
        }
        cattitudeBanner: file(relativePath: { eq: "products_cattitude.png" }) {
          ...FluidImage
        }
        groupfulIcon: file(relativePath: { eq: "products_groupful_icon.png" }) {
          ...FluidImage
        }
        groupfulBanner: file(relativePath: { eq: "products_groupful.png" }) {
          ...FluidImage
        }
        wowshiIcon: file(relativePath: { eq: "products_wowshi_icon.png" }) {
          ...FluidImage
        }
        wowshiBanner: file(relativePath: { eq: "products_wowshi.png" }) {
          ...FluidImage
        }
        clipStoryIcon: file(
          relativePath: { eq: "products_clipstory_icon.png" }
        ) {
          ...FluidImage
        }
        clipStoryBanner: file(relativePath: { eq: "products_clipstory.png" }) {
          ...FluidImage
        }
        picStoryIcon: file(relativePath: { eq: "products_picstory_icon.png" }) {
          ...FluidImage
        }
        picStoryBanner: file(relativePath: { eq: "products_picstory.png" }) {
          ...FluidImage
        }
        motionIcon: file(relativePath: { eq: "products_motion_icon.png" }) {
          ...FluidImage
        }
        motionBanner: file(relativePath: { eq: "products_motion.png" }) {
          ...FluidImage
        }
        awesomeeIcon: file(relativePath: { eq: "products_awesomee_icon.png" }) {
          ...FluidImage
        }
        awesomeeBanner: file(relativePath: { eq: "products_awesomee.png" }) {
          ...FluidImage
        }
      }
    `
  )
  return formatNodes(nodes)
}

export default useProductsPage
