// PicCollage | OnBeat
// PicCut | Motion
// Convert | Widget Go
// Wowshi | HiLite
// Sidebar | Cardee
// Groupful | Salut

const productList = [
  {
    banner: 'piccollageBanner',
    icon: 'piccollageIcon',
    title: 'PicCollage',
    description:
      'PicCollage has been downloaded by over 250 million people around the world. The app provides a canvas to bring photos and videos together with creative elements and templates that evokes the craft of handmade scrapbooking in a digital space. ',
    homePageUrl:
      'https://apps.apple.com/app/apple-store/id448639966?pt=405153&ct=picco_website_home&mt=8',
    productPageOtherAppsUrl: '',
    to: [
      {
        url:
          'https://apps.apple.com/app/apple-store/id448639966?pt=405153&ct=picco_website_product_page&mt=8',
        linkImage: 'appStore'
      },
      {
        url:
          'https://play.google.com/store/apps/details?id=com.cardinalblue.piccollage.google&utm_source=picco_website_product_page',
        linkImage: 'playStore'
      },
      {
        url: 'https://piccollage.com?utm_source=picco_website_product_page',
        linkLabel: 'See website'
      }
    ]
  },
  {
    banner: 'beamBanner',
    icon: 'beamIcon',
    title: 'Beam',
    description:
      'Meet BEAM, AKA your new fave photo-editor. BEAM is a fresh, new app that already packs a punch; offering a range of powerful editing tools. Users are raving - whether its our incredible AI-powered object removal, photo expansion or our unique filters - BEAM will impress you. ',
    homePageUrl:
      'https://apps.apple.com/app/apple-store/id6502189931?pt=405153&ct=picco_website_home&mt=8',
    productPageOtherAppsUrl: '',
    to: [
      {
        url:
          'https://apps.apple.com/app/apple-store/id6502189931?pt=405153&ct=picco_website_product_page&mt=8',
        linkImage: 'appStore'
      }
    ]
  },
  {
    banner: 'noodleBanner',
    icon: 'noodleIcon',
    title: 'Noodle',
    description:
      'Noodle brings the magic of animation to doodles. This innovative app uses AI to add movement to your simple sketches in just a tap, delighting a growing community of creative enthusiasts. Noodle expands the boundaries of digital artistry for casual doodlers, parents, kids and artists alike.',
    homePageUrl:
      'https://apps.apple.com/app/apple-store/id6499508227?pt=405153&ct=picco_website_home&mt=8',
    productPageOtherAppsUrl: '',
    to: [
      {
        url:
          'https://apps.apple.com/app/apple-store/id6499508227?pt=405153&ct=picco_website_product_page&mt=8',
        linkImage: 'appStore'
      },
      {
        url: 'https://noodledoodle.app/',
        linkLabel: 'See website'
      }
    ]
  },
  {
    banner: 'becomeBanner',
    icon: 'becomeIcon',
    title: 'Become',
    description:
      'Become transforms ordinary selfies into extraordinary portraits using AI-powered filters. This innovative app offers a wide range of styles, from K-pop to vintage looks, appealing to a wide range of self-expression enthusiasts. With Become, you can be whoever you want to be.',
    homePageUrl:
      'https://apps.apple.com/app/apple-store/id6504595376?pt=405153&ct=picco_website_home&mt=8',
    productPageOtherAppsUrl: '',
    to: [
      {
        url:
          'https://apps.apple.com/app/apple-store/id6504595376?pt=405153&ct=picco_website_product_page&mt=8',
        linkImage: 'appStore'
      },
      {
        url:
          'https://play.google.com/store/apps/details?id=com.cardinalblue.instantid.android&hl=en&utm_source=picco_website_product_page',
        linkImage: 'playStore'
      }
    ]
  },
  {
    banner: 'visageBanner',
    icon: 'visageIcon',
    title: 'Visage',
    description: '',
    homePageUrl: '',
    productPageOtherAppsUrl:
      'https://apps.apple.com/app/apple-store/id6448083212?pt=405153&ct=picco_website_product_page&mt=8',
    to: []
  },
  {
    banner: 'cardeeBanner',
    icon: 'cardeeIcon',
    title: 'Cardee',
    description: '',
    homePageUrl: '',
    productPageOtherAppsUrl:
      'https://cardee.co/templates?utm_source=picco_website_product_page',
    to: []
  },
  {
    banner: 'enchantBanner',
    icon: 'enchantIcon',
    title: 'Enchant',
    description:
      'If you can imagine it, you can generate the photos through prompts! Simply select a photo and pick a style or customize it with your own prompts. Perfect for making birthday cards or visualizing yourself in various settings!',
    homePageUrl:
      'https://apps.apple.com/app/apple-store/id6446976044?pt=405153&ct=picco_website_product_page&mt=8',
    productPageOtherAppsUrl:
      'https://apps.apple.com/app/apple-store/id6446976044?pt=405153&ct=picco_website_product_page&mt=8',
    to: []
  },
  {
    banner: 'stickerizeBanner',
    icon: 'stickerizeIcon',
    title: 'Stickerize',
    description:
      'Create custom stickers with your own face & send them to group chats! Express better, connect deeper, and laugh harder with your friends and families with relatable stickers.',
    homePageUrl: '',
    productPageOtherAppsUrl: '',
    to: [
      {
        url:
          'https://play.google.com/store/apps/details?id=com.cardinalblue.aisticker&utm_source=picco_website_product_page',
        linkImage: 'playStore'
      }
    ]
  },
  {
    banner: 'chatlyBanner',
    icon: 'chatlyIcon',
    title: 'Chatly',
    description:
      "Turn up your Slack workspaces and meet your new workmate, Chatly! Chatly opens up a private, nimble, and effective communication channel that boosts ChatGPT4's capabilities in no time.",
    homePageUrl: 'https://picbot.co/chatly?utm_source=picco_website_home',
    productPageOtherAppsUrl: '',
    to: [
      {
        url: 'https://picbot.co/chatly?utm_source=picco_website_product_page',
        linkImage: 'slack'
      }
    ]
  },
  {
    banner: 'memeMeBanner',
    icon: 'memeMeIcon',
    title: 'MemeMe',
    description:
      "It's you - but as a meme! Meet MemeMe - our face-swapping, meme-making app. Choose your favorite meme, upload a face, and let AI instantly morph you into your desired memes in a snap!",
    homePageUrl: 'https://onelink.to/juwssv',
    productPageOtherAppsUrl: '',
    to: [
      {
        url:
          'https://apps.apple.com/app/apple-store/id6462672896?pt=405153&ct=picco_website_product_page&mt=8',
        linkImage: 'appStore'
      },
      {
        url:
          'https://play.google.com/store/apps/details?id=com.cardinalblue.aimeme&utm_source=picco_website_product_page',
        linkImage: 'playStore'
      },
      {
        url: 'https://www.mememe.fun/?utm_source=picco_website_product_page',
        linkLabel: 'See website'
      }
    ]
  },
  {
    banner: 'fastCutAiBanner',
    icon: 'fastCutAiIcon',
    title: 'FastCut AI',
    description:
      'Skip hours of editing & trying to make a video of your memories from scratch. FastCut AI does it for you in only 10 seconds! Just pick your photos & videos, scroll through the filters, and apply the one that matches your style. FastCut AI instantly adds all the movie magic!',
    homePageUrl:
      'https://apps.apple.com/app/apple-store/id1604709092?pt=405153&ct=picco_website_home&mt=8',
    productPageOtherAppsUrl:
      'https://apps.apple.com/app/apple-store/id1604709092?pt=405153&ct=picco_website_product_page&mt=8',
    to: []
  },
  {
    banner: 'sidebarBanner',
    icon: 'sidebarIcon',
    showBorder: true,
    title: 'Sidebar',
    description:
      'Turn draining meetings into meaningful discussions. Small group chats are powerful and Sidebar allows these breakouts with ease. Make progress by having real conversations with your team.',
    homePageUrl: 'https://sidebar.us/?utm_source=picco_website_home',
    productPageOtherAppsUrl: '',
    to: [
      {
        url: 'https://sidebar.us/?utm_source=picco_website_product_page',
        linkLabel: 'See website'
      }
    ]
  },
  {
    banner: 'snapJoyBanner',
    icon: 'snapJoyIcon',
    title: 'SnapJoy',
    description:
      'It’s time to level up your photos and selfies with SnapJoy AI Camera! Every moment can be transformed into a work of art with our magical AI filters in seconds.',
    homePageUrl:
      'https://apps.apple.com/app/apple-store/id6474116921?pt=405153&ct=picco_website_home&mt=8',
    productPageOtherAppsUrl: '',
    to: [
      {
        url:
          'https://apps.apple.com/app/apple-store/id6474116921?pt=405153&ct=picco_website_product_page&mt=8',
        linkImage: 'appStore'
      }
    ]
  },
  {
    banner: 'onBeatBanner',
    icon: 'onBeatIcon',
    title: 'OnBeat',
    description:
      'With OnBeat, you can create groovy videos that sync to your favorite beat. Just upload, select your favorite music, and let AI do the magic of syncing your video transition to the rhythm!',
    homePageUrl:
      'https://apps.apple.com/app/apple-store/id1635685232?pt=405153&ct=picco_website_home&mt=8',
    productPageOtherAppsUrl: '',
    to: [
      {
        url:
          'https://apps.apple.com/app/apple-store/id1635685232?pt=405153&ct=picco_website_product_page&mt=8',
        linkImage: 'appStore'
      }
    ]
  },
  {
    banner: 'convertBanner',
    icon: 'convertIcon',
    title: 'Convert',
    description:
      'Want your wallpaper to be animated? Convert - GIF & Live Wallpaper is an easy and sleek way to format your photos for sharing. It helps you convert different image formats into videos, GIFs, or Live Photos.',
    homePageUrl:
      'https://apps.apple.com/app/apple-store/id1524913415?pt=405153&ct=picco_website_home&mt=8',
    productPageOtherAppsUrl: '',
    to: [
      {
        url:
          'https://apps.apple.com/app/apple-store/id1524913415?pt=405153&ct=picco_website_product_page&mt=8',
        linkImage: 'appStore'
      }
    ]
  },
  {
    banner: 'widgetgoBanner',
    icon: 'widgetgoIcon',
    title: 'Widget Go',
    showBorder: true,
    description:
      'Widget Go is an easy-to-use widget maker with various aesthetic widget templates. Widget Go unleashes your creativity and allows you to quickly and easily personalize your home screen.',
    homePageUrl: '',
    productPageOtherAppsUrl:
      'https://apps.apple.com/app/apple-store/id1541792089?pt=405153&ct=picco_website_product_page&mt=8',
    to: []
  },
  {
    banner: 'wowshiBanner',
    icon: 'wowshiIcon',
    title: 'WOWSHI',
    description:
      'Unwind with WOWSHI! The app presents you with a new coloring experience using Japanese washi tape. Discover the latest relaxing activity to express your creativity.',
    homePageUrl: '',
    productPageOtherAppsUrl: 'https://onelink.to/bpdgd3',
    to: []
  },
  {
    banner: 'picCutBanner',
    icon: 'picCutIcon',
    showBorder: true,
    title: 'PicCut',
    description:
      'Remove backgrounds & retouch your photos with just a tap! PicCut is a photo editing app that helps you create more attractive product photos with the help of AI. Boost your sales and showcase your products with PicCut!',
    homePageUrl: '',
    productPageOtherAppsUrl:
      'https://apps.apple.com/app/apple-store/id1623285592?pt=405153&ct=picco_website_product_page&mt=8',
    to: []
  },
  {
    banner: 'motionBanner',
    icon: 'motionIcon',
    title: 'Motion',
    description:
      'Motion lets you create aesthetic social media stories easily. With soft color palettes and graceful templates, Motion refines your photos and videos into beautiful, animated stories in a few taps.',
    homePageUrl: '',
    productPageOtherAppsUrl:
      'https://apps.apple.com/app/apple-store/id1581708533?pt=405153&ct=picco_website_product_page&mt=8',
    to: []
  },
  {
    banner: 'hiLiteBanner',
    icon: 'hiLiteIcon',
    title: 'HiLite',
    description:
      'HiLite finds & combines the most exciting shots in seconds using smart AI technology. Simply choose your videos and let HiLite find the best moments for you. With HiLite, now you have a handy way to instantly shorten your videos into a collection of meaningful moments!',
    homePageUrl: '',
    productPageOtherAppsUrl:
      'https://apps.apple.com/app/apple-store/id1627444989?pt=405153&ct=picco_website_product_page&mt=8',
    to: []
  },
  {
    banner: 'salutBanner',
    icon: 'salutIcon',
    title: 'Salut',
    showBorder: true,
    description:
      'Salut makes saying hello to loved ones quick and easy. Instantly create virtual greeting cards to send to your friends and family in a cute and simple way.',
    homePageUrl: '',
    productPageOtherAppsUrl:
      'https://salut.cards/gallery?utm_source=picco_website_product_page',
    to: []
  },
  {
    banner: 'groupfulBanner',
    icon: 'groupfulIcon',
    showBorder: true,
    title: 'Groupful',
    description:
      'Make birthdays & other occasions more memorable! Simply choose a style and get ready to share heartfelt messages. Our AI-powered greetings are ready to help if you need writing inspirations.',
    homePageUrl: 'https://groupful.app/?utm_source=picco_website_home',
    productPageOtherAppsUrl:
      'https://groupful.app/?utm_campaign=picco_website_product_page',
    to: []
  },
  {
    banner: 'piccovideoBanner',
    icon: 'piccovideoIcon',
    showBorder: true,
    title: 'VDO',
    description:
      'VDO (short for "video") uses AI technology to make video editing quick, fun and easy. The app helps you instantly transform your photos and videos into exciting highlight videos and stories.',
    homePageUrl: '',
    productPageOtherAppsUrl:
      'https://apps.apple.com/app/apple-store/id1545315875?pt=405153&ct=picco_website_product_page&mt=8',
    to: []
  },
  {
    banner: 'cattitudeBanner',
    icon: 'cattitudeIcon',
    showBorder: true,
    title: 'Cattitude',
    description:
      'Cattitude is a personal and fun way to track your daily moods. Choose a cat GIF to describe your current emotions and add a description to complete your daily log.',
    homePageUrl: '',
    productPageOtherAppsUrl:
      'https://apps.apple.com/app/apple-store/id1571679373?pt=405153&ct=picco_website_product_page&mt=8',
    to: []
  },
  {
    banner: 'picStoryBanner',
    icon: 'picStoryIcon',
    title: 'PicStory',
    description:
      'PicStory brings more fun to your social media stories with animated, colorful frames. Simply pick the right frame for your photos or videos, and get ready to share more captivating stories with your family, friends, and followers.',
    homePageUrl: '',
    productPageOtherAppsUrl:
      'https://apps.apple.com/app/apple-store/id1596201786?pt=405153&ct=picco_website_product_page&mt=8',
    to: []
  },
  {
    banner: 'clipStoryBanner',
    icon: 'clipStoryIcon',
    title: 'ClipStory',
    description:
      'With ClipStory, creating awesome memories is easier than ever! The app simplifies the overwhelming video editing experience by instantly combining multiple photos and videos into video collages.',
    homePageUrl: '',
    productPageOtherAppsUrl: '',
    to: [
      {
        url:
          'https://apps.apple.com/app/apple-store/id1604709092?pt=405153&ct=picco_website_home&mt=8',
        linkImage: 'appStore'
      }
    ]
  },
  {
    banner: 'vibesBanner',
    icon: 'vibesIcon',
    title: 'Vibes',
    description:
      'Vibes takes your raw videos and transforms them into a polished shareable video in seconds. The app uses machine learning to find video highlights, applies transitions and adds music to make it easy to share the best moments from a day. ',
    homePageUrl: '',
    productPageOtherAppsUrl:
      'https://apps.apple.com/app/apple-store/id1444385543?pt=405153&ct=picco_website_product_page&mt=8',
    to: []
  },
  {
    banner: 'quickmoBanner',
    icon: 'quickmoIcon',
    title: 'QuickMo',
    showBorder: true,
    description:
      'QuickMo turns your photos into a slideshow video in seconds. The app provides different video canvas sizes to make a perfect square post or a vertical story and allows you to share in the format of videos, GIFs, or Live Photos.',
    homePageUrl: '',
    productPageOtherAppsUrl:
      'https://apps.apple.com/app/apple-store/id1527210837?pt=405153&ct=picco_website_product_page&mt=8',
    to: []
  },
  {
    banner: 'sparkoBanner',
    icon: 'sparkoIcon',
    title: 'Sparko',
    description:
      'Sparko amps up your photos by transforming them into animated GIFs. The auto-cutout feature and fun animations make it easy to turn your photos into something special. ',
    homePageUrl: '',
    productPageOtherAppsUrl:
      'https://apps.apple.com/app/apple-store/id1476511394?pt=405153&ct=picco_website_product_page&mt=8',
    to: []
  },
  {
    banner: 'awesomeeBanner',
    icon: 'awesomeeIcon',
    title: 'Awesomee',
    description:
      'Effortlessly create your awesome bio page with Awesomee. The app simplifies multiple links of your digital accounts into one single link which makes connecting with each other hassle-free.',
    homePageUrl: '',
    productPageOtherAppsUrl: '',
    to: [
      {
        url: 'https://awesom.ee/?utm_source=picco_website_home',
        linkLabel: 'See website'
      }
    ]
  }
]

export default productList
