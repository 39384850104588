import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { withSize } from 'react-sizeme'

const Stack = ({ shadow, children, size, ...rest }) => {
  const { width, height } = size

  const Shadow = styled(BasicShadow)`
    ${shadow};
    width: ${width}px;
    height: ${height}px;
  `

  return (
    <Root {...rest}>
      {children}
      <Shadow />
    </Root>
  )
}

Stack.propTypes = {
  shadow: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  size: PropTypes.object.isRequired
}

Stack.defaultProps = {
  shadow: props => css`
    background-color: ${props.theme.color.primary2};
    bottom: -20px;
    right: -20px;
  `
}

const Root = styled.div`
  position: relative;
`

const BasicShadow = styled.div`
  position: absolute;
  background-color: ${props => props.theme.color.primary2};
`

export default withSize({ monitorHeight: true })(Stack)
